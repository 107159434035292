import { PassStatus, UserProfile } from '@/__generated__/graphql';
import PersonalAdmin from '@/components/cards/PersonalAdmin';
import { LessonsListView } from '@/components/lists/LessonsListView/LessonsListView';
import { PassesListView } from '@/components/lists/PassesListView/PassesListView';
import { Alert, Box, Button, Stack, Typography } from '@mui/joy';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import CashbackView from './CashbackView';

export default function HomeView({
  user,
  lessons,
  passes,
}: {
  user: UserProfile;
  lessons: LinkedLesson[];
  passes: PartialPass[];
}) {
  return (
    <Stack gap={4} p={2} pt={4} pb={12}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography level="h3">
          <FormattedMessage defaultMessage={`Здравствуйте, {name} 💕`} values={{ name: user.name }} />
        </Typography>
      </Stack>

      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ближайшие занятия" />
        </Typography>
        {lessons.length === 0 ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography color="neutral">
                <FormattedMessage defaultMessage="Нет записей" />
              </Typography>
            </Box>

            <Button variant="outlined" component={RouterLink} to="/">
              <FormattedMessage defaultMessage="Записаться" />
            </Button>
          </>
        ) : (
          <LessonsListView lessons={lessons} />
        )}
      </Stack>

      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ваши абонементы" />
        </Typography>
        <PassesListView passes={passes} />

        {passes.filter((pass) => ![PassStatus.Expired, PassStatus.NoHours, PassStatus.Refunded].includes(pass.status))
          .length === 0 && (
          <Alert variant="outlined" size="sm">
            <Typography level="body-sm">
              <FormattedMessage defaultMessage="Для оформления абонемента пожалуйста обратитесь к администратору" />
            </Typography>
          </Alert>
        )}
      </Stack>

      {user ? <PersonalAdmin user={user} /> : null}

      {user ? <CashbackView user={user} /> : null}
    </Stack>
  );
}
