import { LessonType } from "@/__generated__/graphql";
import { LessonStatus, lessonHasTeacherReplacement } from "@/modules/app";
import { Chip, Stack } from "@mui/joy";
import { FormattedMessage } from "react-intl";

const LessonStatusChip = ({ lesson }: { lesson: LinkedLesson }) =>
  ({
    [LessonStatus.noSeats]: (
      <Chip color="danger">
        <FormattedMessage defaultMessage="Нет мест" />
      </Chip>
    ),
    [LessonStatus.fewSeats]: (
      <Chip color="warning">
        <FormattedMessage
          defaultMessage="{count, plural, one {{count} место} few {{count} места} many {{count} мест} other {{count} мест}}"
          values={{ count: lesson.remainingSeats }}
        />
      </Chip>
    ),
    [LessonStatus.booked]: (
      <Chip color="success">
        <FormattedMessage defaultMessage="Вы записаны" />
      </Chip>
    ),
    [LessonStatus.waiting]: (
      <Chip color="warning" variant="soft">
        <FormattedMessage defaultMessage="Вы в листе ожидания" />
      </Chip>
    ),
    [LessonStatus.passed]: (
      <Chip color="success">
        <FormattedMessage defaultMessage="Проведено" />
      </Chip>
    ),
    [LessonStatus.bookingNotAllowed]: (
      <Chip color="neutral">
        <FormattedMessage defaultMessage="Запись недоступна" />
      </Chip>
    ),
    [LessonStatus.penalty]: (
      <Chip color="neutral">
        <FormattedMessage defaultMessage="Списано" />
      </Chip>
    ),
    [LessonStatus.none]: null,
  })[lesson.status as LessonStatus];

const LessonTypeChip = ({ lesson }: { lesson: LinkedLesson }) => {
  if (lesson.type === LessonType.Trial) {
    return (
      <Chip color="success">
        <FormattedMessage defaultMessage="🔥 Пробное" />
      </Chip>
    );
  }

  return null;
};

const LessonTeacherReplacementChip = ({ lesson }: { lesson: LinkedLesson }) => {
  if (lessonHasTeacherReplacement(lesson)) {
    return (
      <Chip color="primary">
        <FormattedMessage defaultMessage="Замена" />
      </Chip>
    );
  }

  return null;
};

export function LessonChip({ lesson }: { lesson: LinkedLesson }) {
  return (
    <Stack direction="row" gap={1}>
      <LessonTypeChip lesson={lesson} />
      <LessonTeacherReplacementChip lesson={lesson} />
      <LessonStatusChip lesson={lesson} />
    </Stack>
  );
}
