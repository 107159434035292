export enum LessonStatus {
  none = "none",
  noSeats = "noSeats",
  fewSeats = "fewSeats",
  booked = "booked",
  passed = "passed",
  penalty = "penalty",
  bookingNotAllowed = "bookingNotAllowed",
  waiting = "waiting",
}

export enum SignInMode {
  sms = "sms",
  signInAs = "sign-in-as",
  identifier = "identifier",
}
