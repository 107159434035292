import { UserProfile } from '@/__generated__/graphql';
import { Phone } from '@mui/icons-material';
import { Avatar, Box, Button, IconButton, Stack, Typography } from '@mui/joy';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function PersonalAdmin({ user }: { user: UserProfile }) {
  return (
    <Stack>
      {user.administrator &&
        user.administrator.appName &&
        user.administrator.avatar &&
        user.administrator.phone &&
        user.administrator.telegram && (
          <Stack gap={2}>
            <Typography level="title-lg" sx={{ paddingTop: '10px' }}>
              <FormattedMessage defaultMessage="Ваш личный администратор" />
            </Typography>
            <Link to={`tel://${user.administrator.phone}`} target="blank" style={{ textDecoration: 'none' }}>
              <Stack direction="row">
                <Avatar
                  size="lg"
                  sx={{ mr: 2 }}
                  src={user.administrator.avatar.thumbnail || user.administrator.avatar.url}
                />
                <Stack flex={1} direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Box>
                    <Typography level="title-md">
                      <b>{user.administrator.appName}</b>
                    </Typography>
                    <Typography level="body-sm" textColor="neutral.400">
                      {user.administrator.phone}
                    </Typography>
                  </Box>

                  <IconButton
                    sx={{
                      aspectRatio: '1/1',
                    }}
                    variant="outlined"
                  >
                    <Phone />
                  </IconButton>
                </Stack>
              </Stack>
            </Link>

            <Button
              variant="outlined"
              component={Link}
              to={`https://t.me/${user.administrator.telegram}`}
              target="blank"
            >
              <FormattedMessage defaultMessage="Связаться в Telegram" />
            </Button>
          </Stack>
        )}
    </Stack>
  );
}
