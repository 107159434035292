import { UserProfile } from '@/__generated__/graphql';
import { Star } from '@mui/icons-material';
import { Alert, Avatar, Box, IconButton, LinearProgress, Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const getCashbackPercentage = (user: UserProfile) => {
  return Math.min(Math.floor(user.bonusBalance / 1000), 5);
};

export default function CashbackView({ user }: { user: UserProfile }) {
  const [bonusModalOpen, setBonusModalOpen] = useState(false);

  return (
    <>
      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Система лояльности" />
        </Typography>

        <Alert color="warning">
          <Typography level="body-sm">
            <FormattedMessage defaultMessage="Кешбэк начнет автоматически начисляться с 1 апреля" />
          </Typography>
        </Alert>

        <Stack direction="row">
          <Avatar size="lg" sx={{ mr: 2 }}>
            <Star sx={{ color: '#D9347E' }} />
          </Avatar>
          <Stack flex={1} direction="row" sx={{ justifyContent: 'space-between' }}>
            <Box>
              <Typography level="title-md">
                <b>
                  <FormattedMessage defaultMessage="Кешбэк {value}%" values={{ value: getCashbackPercentage(user) }} />
                </b>
              </Typography>
              <Typography level="body-sm" textColor="neutral.400">
                {user.totalSpent < 5000 ? (
                  <FormattedMessage
                    defaultMessage="До {level}-го уровня: {value} BYN"
                    values={{
                      value: Math.round(1000 - (user.totalSpent % 1000)),
                      level: Math.floor(user.totalSpent / 1000) + 1,
                    }}
                  />
                ) : (
                  <FormattedMessage defaultMessage="Максимальный уровень" />
                )}
              </Typography>
            </Box>

            <IconButton
              sx={{
                aspectRatio: '1/1',
              }}
              variant="outlined"
              onClick={() => setBonusModalOpen(true)}
            >
              {user.bonusBalance.toFixed(1)}
            </IconButton>
          </Stack>
        </Stack>
        <LinearProgress
          color="primary"
          determinate
          value={user.totalSpent < 5000 ? (user.totalSpent % 1000) / 10 : 100}
        />
      </Stack>

      <Modal open={bonusModalOpen} onClose={() => setBonusModalOpen(false)}>
        <ModalDialog>
          <Typography level="title-lg">
            <FormattedMessage
              defaultMessage="На вашем счету {value} бонусных баллов"
              values={{ value: user.bonusBalance.toFixed(1) }}
            />
          </Typography>
          <Typography level="title-sm">
            <FormattedMessage defaultMessage="Баллы можно использовать для оплаты до 50% стоимости любой покупки в AMANI" />
          </Typography>
        </ModalDialog>
      </Modal>
    </>
  );
}
