import { useBookLessonMutation } from "@/__generated__/graphql";
import { AppModal } from "@/components/modals/AppModal/AppModal";
import { extractGraphQLErrorMessage } from "@/helpers";
import { useAppContext } from "@/modules/app";
import Auth from "@/modules/auth";
import { isBefore } from "date-fns";
import { maxBy } from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getAvailablePassesForLessonBooking } from "../helpers/getAvailablePassesForLessonBooking";
import BookLessonAuthView from "../views/booking/BookLessonAuthView";
import BookLessonButtonView from "../views/booking/BookLessonButtonView";
import ChoosePassView from "../views/booking/BookLessonChoosePassView";
import NoAvailablePassesView from "../views/booking/NoAvailablePassesView";
import PersonalAdmin from "@/components/cards/PersonalAdmin";
import { FormattedMessage } from "react-intl";
import { passHasBranchAccess } from "../helpers/passHasBranchAccess";
import { useAddUserToWaitingListMutation } from "@/__generated__/graphql";

export default function BookLesson({ lesson }: { lesson: LinkedLesson }) {
  const { allPasses, user, location } = useAppContext();
  const intl = useIntl();
  const [openChoosePass, setOpenChoosePass] = useState(false);
  const [openNoAvailablePasses, setOpenNoAvailablePasses] = useState(false);
  const [openNeedsAuth, setOpenNeedsAuth] = useState(false);
  const [openBranchErr, setOpenBranchErr] = useState(false);

  const [bookLesson, { loading: loadingBook }] = useBookLessonMutation();
  const [addUserToWaitingListMutation, { loading: loadingWait }] = useAddUserToWaitingListMutation();
  
  const onClick = () => {
    const passes = allPasses
      ? getAvailablePassesForLessonBooking(allPasses, lesson)
      : [];
    const lessonBranchRecId = lesson.group?.branch?.recId;
    const hasValidPass = passes.some(pass => passHasBranchAccess(pass, lessonBranchRecId));

    if (!user) setOpenNeedsAuth(true);
    else {
      if (!passes.length) setOpenNoAvailablePasses(true);
      else {
        if (!hasValidPass) setOpenBranchErr(true);
        else setOpenChoosePass(true);
      }
    }
  };

  const onBook = (pass: PartialPass) => {
    setOpenChoosePass(false);

    const lessonBranchRecId = lesson.group?.branch?.recId;
    if (!passHasBranchAccess(pass, lessonBranchRecId)) setOpenBranchErr(true)
    else {
      bookLesson({
        variables: {
          lessonRecId: lesson.recId,
          passRecId: pass.recId,
        },
      })
        .then(() => {
          toast.success(
            intl.formatMessage({
              defaultMessage: "Вы успешно записаны на занятие!",
            })
          );
        })
        .catch((error) => {
          toast.error(extractGraphQLErrorMessage(error));
        });
    }
  };

  const onWait = (pass: PartialPass) => {
    setOpenChoosePass(false);

    const lessonBranchRecId = lesson.group?.branch?.recId;
    if (!passHasBranchAccess(pass, lessonBranchRecId)) setOpenBranchErr(true)
    else {
      addUserToWaitingListMutation({
        variables: {
          lessonRecId: lesson.recId,
          waitingListPassRecId: pass.recId,
        },
      })
        .then(() => {
          toast.success(
            intl.formatMessage({
              defaultMessage: "Вы успешно записаны в Лиcт ожидания!",
            })
          );
        })
        .catch((error) => {
          toast.error(extractGraphQLErrorMessage(error));
        });
    }
  }

  if (
    !lesson.bookingAllowed ||
    lesson.passRecId ||
    lesson.waitingListPassRecId ||
    isBefore(lesson.startDateTime, new Date())
  )
    return null;

  return (
    <>
      <BookLessonButtonView onClick={onClick} loading={loadingWait || loadingBook} display={lesson.remainingSeats <= 0 ? "wait" :  "book"}/>
      <AppModal open={openChoosePass} onClose={() => setOpenChoosePass(false)}>
        <ChoosePassView
          onClose={() => setOpenChoosePass(false)}
          passes={getAvailablePassesForLessonBooking(allPasses || [], lesson)}
          onChoose={lesson.remainingSeats <= 0 ? onWait : onBook}
          rules={lesson.remainingSeats <= 0 ? [intl.formatMessage({defaultMessage: "Как только освободится место - мы запишем Вас на занятие и пришлем СМС. Если место так и не освободиться - мы позвоним вам за 1 день до занятия и предупредим ❤️"}), location.lessonsRules].join('\n') : location.lessonsRules}
          display={lesson.remainingSeats <= 0 ? "wait" : "book"}
        />
      </AppModal>
      <AppModal
        open={openNoAvailablePasses}
        onClose={() => setOpenNoAvailablePasses(false)}
      >
        <NoAvailablePassesView
          lesson={lesson}
          pass={maxBy(allPasses, (pass) => pass.activationDate)}
        />
      </AppModal>
      <AppModal open={openNeedsAuth} onClose={() => setOpenNeedsAuth(false)}>
        <BookLessonAuthView>
          <Auth />
        </BookLessonAuthView>
      </AppModal>
      <AppModal open={openBranchErr} onClose={() => setOpenBranchErr(false)}>
        <FormattedMessage defaultMessage="Для записи в данный филиал нужен другой тип абонемента, пожалуйста обратитесь к вашему администратору" />
        {user ? <PersonalAdmin user={user} /> : null}
      </AppModal>
    </>
  );
}
