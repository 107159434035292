import { Button, useTheme } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function BookLessonButtonView({
  onClick,
  loading,
  display = 'book',
}: {
  onClick: () => void;
  loading: boolean;
  display?: 'wait' | 'book';
}) {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      loading={loading}
      color={display === 'wait' ? 'warning' : 'primary'}
      variant={display === 'wait' ? 'soft' : 'solid'}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.badge,
        borderRadius: 0,
        padding: 2,
      }}
      size="md"
    >
      {display === 'wait' 
      ? <FormattedMessage defaultMessage="Лист ожидания" /> 
      : <FormattedMessage defaultMessage="Записаться" />}
    </Button>
  );
}
