import { Button, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function CancelLessonSuccessView({
  onBookClick,
  onClose,
}: {
  lesson: LinkedLesson;
  onBookClick: () => void;
  onClose: () => void;
}) {
  return (
    <Stack gap={2}>
      <Typography level="title-md">
        <FormattedMessage defaultMessage="Запись отменена" />
      </Typography>
      <Typography level="h2">🥺</Typography>
      <Typography level="body-md">
        <FormattedMessage defaultMessage="Запишемся на новое занятие?" />
      </Typography>
      <Stack gap={1}>
        <Button onClick={onBookClick}>
          <FormattedMessage defaultMessage="Записаться" />
        </Button>
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage defaultMessage="Закрыть" />
        </Button>
      </Stack>
    </Stack>
  );
}
