import { Branch, Group, Lesson, Teacher } from "@/__generated__/graphql";
import { isAfter } from "date-fns";
import { intersection, keyBy, map } from "lodash";
import { LessonStatus } from "../../types";

const mapLessonStatus = (
  lesson: Lesson,
  userPasses?: PartialPass[] | null
): Partial<LinkedLesson> & { status: LessonStatus } => {
  const passesRecIds = map(userPasses, "recId");
  const passRecId = intersection(passesRecIds, lesson.bookingsRecIds)?.[0];
  const hasPenalty = intersection(passesRecIds, lesson.penaltiesRecIds).length > 0;
  const waitingListPassRecId = intersection(passesRecIds, lesson.waitingListRecIds)?.[0];

  if (isAfter(lesson.startDateTime, new Date())) {
    if (passRecId) return { status: LessonStatus.booked, passRecId };
    else if (!lesson.bookingAllowed) {
      return { status: LessonStatus.bookingNotAllowed };
    } else if (waitingListPassRecId) 
      return { status: LessonStatus.waiting, waitingListPassRecId };
      else if (lesson.remainingSeats <= 0)
      return { status: LessonStatus.noSeats };
    else if (lesson.remainingSeats < 3)
      return { status: LessonStatus.fewSeats };
  } else {
    if (hasPenalty) return { status: LessonStatus.penalty };
    if (lesson.passed) return { status: LessonStatus.passed };
  }

  return { status: LessonStatus.none };
};

export const mapLessons = (
  branches: Branch[],
  teachers: Teacher[],
  groups: Group[],
  lessons: Lesson[],
  passes?: PartialPass[] | null,
): LinkedLesson[] => {
  const branchesDict = keyBy(branches, "recId");
  const teachersDict = keyBy(teachers, "recId");

  const linkedGroups = groups.map((group) => ({
    ...group,
    branch: branchesDict[String(group.branchRecId)],
    teacher: teachersDict[String(group.teacherRecId)],
  }));

  const groupsDict = keyBy(linkedGroups, "recId");

  return lessons.map((lesson) => ({
    ...lesson,
    ...mapLessonStatus(lesson, passes),
    teacher: teachersDict[String(lesson.teacherRecId)],
    group: groupsDict[String(lesson.groupRecId)],
  }));
};

export const lessonHasTeacherReplacement = (lesson: LinkedLesson) =>
  lesson.teacher &&
  lesson.group?.teacher &&
  lesson.teacher.recId !== lesson.group?.teacher.recId;
