import { PassesListView } from "@/components/lists/PassesListView/PassesListView";
import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { Alert, Button, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function BookLessonChoosePassView({
  passes,
  onChoose,
  onClose,
  rules,
  display = 'book',
}: {
  passes: PartialPass[];
  onChoose: (pass: PartialPass) => void;
  onClose: () => void;
  rules?: string | null;
  display?: 'wait' | 'book'
}) {
  return (
    <Stack gap={2}>
      <Typography level="title-md">
        {display === "book" ? <FormattedMessage defaultMessage="Запись на занятие" />
        : <FormattedMessage defaultMessage="Запись в Лист ожидания" />}
      </Typography>

      {rules && (
        <Alert>
          <DescriptionSectionView content={rules} />
        </Alert>
      )}

      {passes.length === 1 && (
        <Stack gap={1}>
          <Button 
            onClick={() => onChoose(passes[0])}
            color={display === 'wait' ? 'warning' : 'primary'}
            variant={display === 'wait' ? 'soft' : 'solid'}  
          >
            {display === 'book' 
              ? <FormattedMessage defaultMessage="Записаться" /> 
              : <FormattedMessage defaultMessage="Записаться в Лист ожидания" />}
          </Button>
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage defaultMessage="Закрыть" />
          </Button>
        </Stack>
      )}

      {passes.length > 1 && (
        <Stack gap={1}>
          <Typography level="body-sm">
            <FormattedMessage defaultMessage="Выберите абонемент для записи" />
          </Typography>
          <PassesListView passes={passes} onClick={onChoose} />
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage defaultMessage="Закрыть" />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
