import { Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { PassStatus, PassCategory } from "@/__generated__/graphql";
import { format } from "date-fns";

const formatDate = (date: Date) => format(date, "d.MM.yy");

interface PassCardContentViewProps {
  pass: PartialPass;
}

export const PassCardContentView = ({ pass }: PassCardContentViewProps) => {
  if (!((pass.activationDate && pass.deactivationDate) || pass.active)) {
    return null;
  }

  return (
    <Typography level="body-sm">
      {pass.activationDate && pass.deactivationDate && (
        <>
          {formatDate(pass.activationDate)}
          {" - "}
          {formatDate(pass.deactivationDate)}
        </>
      )}
      {pass.kind && pass.kind.category && 
        (pass.kind.category === PassCategory.Standart || 
         pass.kind.category === PassCategory.Package) && 
        pass.status !== PassStatus.Expired && (
          <>
            {" · "}
            <FormattedMessage
              defaultMessage="{count, plural, one {{count} час} few {{count} часа} many {{count} часов} other {{count} ч.}}"
              values={{
                count: pass.remainingVisitHours,
              }}
            />
          </>
        )}
    </Typography>
  );
};
