import { Card, Link, Stack } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import { EmptyListView } from "../EmptyListView";
import { PassStatusChip } from "./PassStatusChip";
import { PassCardContentView } from "../../cards/PassCardContentView";

export const PassesListView = ({
  passes,
  onClick,
}: {
  passes: PartialPass[];
  onClick?: (pass: PartialPass) => void;
}) => {
  if (passes.length === 0) return <EmptyListView />;
  
  return (
    <Stack gap={2}>
      {passes.map((pass) => {
        if (!pass.kind?.name) return null;

        return (
          <Card key={pass.recId} sx={{ gap: 1, bgcolor: "white" }}>
            <Link
              onClick={(e) => {
                if (onClick) {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick(pass);
                }
              }}
              component={RouterLink}
              to={`/passes/${pass.recId}`}
              underline="none"
              fontWeight="md"
              fontSize="md"
              color="neutral"
              textColor="text.primary"
              overlay
            >
            {pass.kind?.name}
            </Link>
            <PassCardContentView pass={pass}/>
            <PassStatusChip pass={pass} />
          </Card>
        );
      })}
    </Stack>
  );
};
